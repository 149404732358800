import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

import locales from '../../utils/locales';
import { removeTranslateField } from '../../utils/localization';

export default function localize(Component) {
  // eslint-disable-next-line react/prefer-stateless-function
  return class Localize extends React.PureComponent {
    // eslint-disable-next-line react/static-property-placement
    static propTypes = {
      data: PropTypes.object,
      pageContext: PropTypes.shape({
        locale: PropTypes.object,
        unlocalizedPath: PropTypes.string,
      }),
    };

    render() {
      const { pageContext, data } = this.props;
      const { locale, unlocalizedPath } = pageContext;
      const pageData = removeTranslateField(data);
      return (
        <StaticQuery
          query={graphql`
            query {
              site {
                siteMetadata {
                  siteUrl
                }
              }
            }
          `}
          render={(settings) => {
            const { siteUrl } = settings.site.siteMetadata;
            return (
              <>
                <Helmet
                  htmlAttributes={{
                    lang: locale.id,
                  }}
                >
                  {/* Weird hack for matchPath */}
                  {/* https://github.com/gatsbyjs/gatsby/issues/13946 */}
                  <link
                    rel="canonical"
                    href={
                      siteUrl +
                      (locale.isDefault
                        ? unlocalizedPath
                        : `/${locale.id}${unlocalizedPath}`)
                    }
                  />
                  <meta
                    property="og:url"
                    content={
                      siteUrl +
                      (locale.isDefault
                        ? unlocalizedPath
                        : `/${locale.id}${unlocalizedPath}`)
                    }
                  />
                  {locales.map((altLocale) => (
                    <link
                      key={altLocale.id}
                      rel="alternate"
                      hrefLang={altLocale.id}
                      href={
                        siteUrl +
                        (altLocale.isDefault
                          ? unlocalizedPath
                          : `/${altLocale.id}${unlocalizedPath}`)
                      }
                    />
                  ))}
                </Helmet>
                <Component {...this.props} data={pageData} />
              </>
            );
          }}
        />
      );
    }
  };
}
