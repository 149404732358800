// Removes annoying translate field from resolver response,
// so you can just do {example} instead of {example.translate}
// eslint-disable-next-line import/prefer-default-export
export function removeTranslateField(value) {
  if (value === null) return undefined;
  if (typeof value !== 'object') return value;
  // eslint-disable-next-line no-prototype-builtins
  if (value.hasOwnProperty('localized')) return value.localized;
  if (Array.isArray(value)) {
    return value.map((innerValue) => removeTranslateField(innerValue));
  }
  return Object.keys(value).reduce((result, key) => {
    // eslint-disable-next-line no-param-reassign
    result[key] = removeTranslateField(value[key]);
    return result;
  }, {});
}

export function localizeStaticQuery(languageCode) {
  const languages = [languageCode, 'en']; // last language in array is default;
  const localize = (value) => {
    if (Array.isArray(value)) {
      return value.map((v) => localize(v, languages));
    }
    if (typeof value === 'object') {
      if (/^locale[A-Z]/.test(value._type)) {
        const language = languages.find((lang) => value[lang]);
        return value[language];
      }
      return Object.keys(value).reduce((result, key) => {
        // eslint-disable-next-line no-param-reassign
        result[key] = localize(value[key], languages);
        return result;
      }, {});
    }
    return value;
  };
  return localize;
}
