import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

import LocaleContext from '../../context/LocaleContext';

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      metaData: siteMetadata {
        title
        author
        url: siteUrl
      }
    }
  }
`;

export default function SEO({ description, meta, title, image }) {
  const { locale } = useContext(LocaleContext);
  return (
    <StaticQuery
      query={detailsQuery}
      render={(data) => {
        const { metaData } = data.site;
        const ogImage = image || `${metaData.url}/open-graph.png`;
        return (
          <Helmet
            htmlAttributes={{
              lang: locale.id,
            }}
            title={title}
            titleTemplate={`%s | ${metaData.title}`}
            meta={[
              {
                name: `description`,
                content: description,
              },
              {
                property: `og:title`,
                content: title,
              },
              {
                property: `og:description`,
                content: description,
              },
              {
                property: `og:image`,
                content: ogImage,
              },
              {
                property: `og:image:width`,
                content: 1200,
              },
              {
                property: `og:image:height`,
                content: 630,
              },
              {
                property: `og:type`,
                content: `website`,
              },
              {
                name: `twitter:card`,
                content: `summary`,
              },
              {
                name: `twitter:creator`,
                content: metaData.author,
              },
              {
                name: `twitter:title`,
                content: title,
              },
              {
                name: `twitter:description`,
                content: description,
              },
              {
                name: `google`,
                content: 'notranslate',
              },
            ].concat(meta)}
          />
        );
      }}
    />
  );
}

SEO.defaultProps = {
  meta: [],
};

SEO.propTypes = {
  description: PropTypes.string,
  image: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};
